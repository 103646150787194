import * as React from "react"
import Layout from "../components/layout"
import Kv from "../components/kv"
import TopMenu from "../components/menu-top"
import TopPage from "../components/toppage"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../components/footer"
import "./common.css"
import Head from "../components/header"

const IndexPage = () => (
  <Layout>
    <Head pageTitle="GAZIRU, Inc." pageLang="ja" />
    <TopMenu />
    <div id="top"></div>
    <Kv />
    <Container className="container">
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
        <TopPage />
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)


export default IndexPage
