import * as React from "react"
import { Container, Row, Col} from 'react-bootstrap'
import * as Style from './kv.module.css'
import { useTranslation } from 'react-i18next'

// key visual

function Kv() {
  const { t } = useTranslation()

  return (
    <div className={Style.wrap}>
      <div className={Style.overlay}></div>
      <Container className={Style.content}>
        <Row>
          <Col sm={12} className="mx-auto">
            <div className={Style.siteHeading}>
              <h1>
                G A Z I R U
              </h1>
              <span>{t('key1')}</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Kv